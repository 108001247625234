import React, { Component } from "react";
import { navigate, graphql } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";

import Layout from "../../../../components/layout";
import SEO from "../../../../components/seo";
import styles from "./index.module.css";
import { setCurrentModule, setCurrentStep } from "../../../../state/actions";
import { func, shape, object } from "prop-types";

class ProcessIndex extends Component {
  state = {
    hasSubmitted: false,
    hasSeenFeedback: false
  };

  static propTypes = {
    handleNavigate: func,
    goToStep: func,
    data: shape({
      file: shape({
        childImageSharp: shape({
          fixed: object
        })
      })
    })
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(2);
    this.props.goToStep(3);
  }

  goForward = () => {
    navigate("/modules/travel/process/out-of-state-video");
  };

  goBack = () => {
    navigate("/modules/travel/process/");
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Travel - Process"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <div className={styles.openingText}>
            <p>
              Remember your trip with Roger? Since his parents retain the right
              to let their child travel out of state, let's go through the
              process to get permission for Roger to go with you to Disney
              World!
            </p>
          </div>
          <div className={styles.rogerImage}>
            <Img fluid={this.props.data.file.childImageSharp.fluid} />
          </div>
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.goForward}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export const query = graphql`
  query {
    file(name: { eq: "roger" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default connect(
  stateToProps,
  dispatchToProps
)(ProcessIndex);
